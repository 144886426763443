import React from 'react';
import PropTypes from 'prop-types';

import {
  Description,
  Value,
  Wrapper,
  ImageWrapper,
} from './InfoBox.styles';

const InfoBox = ({ imagePath, text }) => (
  <Wrapper>
    <ImageWrapper>
      <Value src={imagePath} alt={text || ''} />
    </ImageWrapper>
    <Description>{text}</Description>
  </Wrapper>
);

InfoBox.propTypes = {
  imagePath: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default InfoBox;
