import styled from 'styled-components';

const Description = styled.div`
  font-size: 20px;
  display: flex;
  justify-content: center;
  padding: 50px 15px 15px;
`;

const Title = styled.div`
  font-size: 34px;
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;

  &::after {
    display: block;
    content: '';
    width: 100px;
    height: 5px;
    background-color: ${(props) => props.theme.primary4Color};
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-top: 36px;
    font-size: 22px;
    margin-bottom: 4px;

    &::after {
      width: 69px;
      height: 4px;
      bottom: 8px;
    }
  }
`;

const Bold = styled.div`
  font-weight: 900;
`;

const Normal = styled.div`
  font-weight: 300;
`;

export {
  Description, Title, Bold, Normal,
};
