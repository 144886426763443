import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import withSizes from 'utils/withSizes';
import MountComponent from 'components/MountComponent';
import { VisibilityContainer, ContainerMain } from 'utils/styles';

import config from 'config';

import _get from 'lodash/get';
import OffersList from './components/OffersList/OffersList';
import {
  Description, Title, Bold, Normal,
} from './InvestmentOffers.styles';
import { InvestmentShape } from './InvestmentOffers.shapes';
import messages from './InvestmentOffers.message';

const getNumberOfItems = (screenType) => {
  switch (screenType) {
    case 'lg':
      return 5;
    case 'md':
      return 4;
    case 'sm':
      return 3;
    default:
      return 3;
  }
};

const InvestmentSlider = ({
  items,
  screenType,
  mountedComponent,
  isPromotionsActive,
}) => {
  const newItems = items.map((el) => {
    if (_get(config.investmentWithCustomOfferLink, el.id, false)) {
      const customLink = config.investmentWithCustomOfferLink[el.id];
      return ({
        ...el,
        goTo: {
          route: customLink.route,
          params: customLink.params,
        },
      });
    }
    if (el.id === config.investmentIds.businessPremises) {
      return ({
        ...el,
        goTo: {
          route: 'wyszukiwarka',
          params: {
            typeSelected: config.search.typeIds.businessPremises,
          },
        },
      });
    }
    if (el.title === 'Promocje') {
      return ({
        ...el,
        title: ' ',
        goTo: {
          route: 'wyszukiwarka',
          params: {
            typeSelected: 11,
          },
        },
      });
    }
    if (el.fieldInvestmentUpcoming) {
      return ({
        ...el,
        goTo: {
          route: 'contact',
        },
      });
    }
    return ({
      ...el,
      details: {
        route: 'investmentTableSearch',
        params: { id: el.id },
      },
      goTo: {
        route: 'investmentDescription',
        params: { id: el.slug ? el.slug : el.id },
      },
    });
  });

  const numberOfItems = mountedComponent ? getNumberOfItems(screenType) : undefined;

  return (
    <ContainerMain>
      <VisibilityContainer sm md lg>
        <Description><FormattedMessage {...messages.description} /></Description>
      </VisibilityContainer>
      <Title>
        <Bold><FormattedMessage {...messages.titleFirstPart} /></Bold>
        <div>&nbsp;</div>
        <Normal><FormattedMessage {...messages.titleSecondPart} /></Normal>
      </Title>
      <OffersList
        items={newItems}
        slidesToShow={numberOfItems}
        isPromotionsActive={isPromotionsActive}
      />
    </ContainerMain>
  );
};

InvestmentSlider.propTypes = {
  isPromotionsActive: PropTypes.number.isRequired,
  mountedComponent: PropTypes.bool.isRequired,
  screenType: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(InvestmentShape),
};

InvestmentSlider.defaultProps = {
  items: [],
};

export default withSizes()(MountComponent(InvestmentSlider));
