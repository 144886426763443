import styled from 'styled-components';

const OffersListitemWrapper = styled.li`
  margin: 5px;
  flex-basis: calc(33.3% - 10px);
  list-style: none;
  height: min-content;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 5px auto;
    max-width: 380px;
    flex-basis: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: right;
`;

const ItemHeader = styled.h3`
  font-size: 19px;
  margin: 28px 0 13px 0;
  text-transform: uppercase;
  color: ${(props) => props.theme.secondary9Color};
  letter-spacing: 1.3px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    text-align: center;
    font-size: 12px;
    margin: 10px 0 5px 0;
  }
`;

export const LineCharacter = styled.span`
  margin: 0 4px;
`;

const ItemDescription = styled.p`
  font-size: 13px;
  line-height: 2.3;
  margin-bottom: 33px;
  opacity: 0.35;
  font-weight: 400;
  letter-spacing: 1.3px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    text-align: center;
    line-height: 2;
    font-size: 9px;
    margin-bottom: 15px;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export {
  OffersListitemWrapper, Image, ItemHeader, ItemDescription,
};
