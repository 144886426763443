import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 55px 10px;
  text-align: center;
  line-height: 1;
  color: ${(props) => props.theme.secondary13Color};
  background-color: ${(props) => props.theme.secondary11Color};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 40px 10px;
  }
`;

const Value = styled.img`
  max-width: 100%;
  height: auto;
`;

const ImageWrapper = styled.div`
  display: block;
  padding-bottom: 20px;
  min-height: 70px;
`;

const Description = styled.div`
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
`;

export {
  Wrapper,
  Value,
  Description,
  ImageWrapper,
};
