import styled from 'styled-components';

const OffersListWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const OffersListUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
`;

export { OffersListWrapper, OffersListUl };
